
export const initTranslate = (languages: any): (() => void) => {

    // Get list of languages to include from config
    //console.log('translate langs ' + languages)
    if(languages==='')
      return () => {};

    const includedLanguages = languages
    
    //console.log('includedLanguages', includedLanguages);

    // Define a regex to validate the language list
    const validLanguageRegex = /^(?:[a-z]{2}|[a-z]{2}-[A-Z]{2})(?:,(?:[a-z]{2}|[a-z]{2}-[A-Z]{2}))*$/;
    const defaultLanguages = 'ar,zh-CN,en,fr,ht,ko,pl,ru,es,vi';
    let languagesToUse = includedLanguages;
    
    // Throw an error if the language list is invalid and use the default language list
    if (!validLanguageRegex.test(includedLanguages)) {
      console.error('The language list is invalid, using default language list');
      languagesToUse = defaultLanguages;
    }

    //console.log('languagesToUse', languagesToUse);

    // Format the languages to be used in the Google Translate script
    const formattedLanguages = languagesToUse
    ? languagesToUse
        .split(',')
        .map((lang: string) => lang === 'zh-CN' ? lang : lang.slice(0, 2))
        .join(',')
    : '';
    //console.log('formattedLanguages', formattedLanguages);

    const existingScript = document.getElementById("google-translate-script");
    if (existingScript) {
        existingScript.remove(); // Remove old script to avoid duplication
    }
    const existingElementInit = document.getElementById("google-translate-element-init");
    if (existingElementInit) {
        existingElementInit.remove(); // Remove old initialization script
    }

    const gTranslateScript = document.createElement("script");
    gTranslateScript.id = "google-translate-script";
    gTranslateScript.async = true;
    gTranslateScript.type = "text/javascript";
    gTranslateScript.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  
    const gElementInit = document.createElement("script");
    gElementInit.id = "google-translate-element-init";
    gElementInit.type = "text/javascript";

    // Set languages to be included, enforcing english inclusion
    gElementInit.textContent = `function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'en',
          layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
          autoDisplay: false,
          includedLanguages: 'en,${formattedLanguages}',
        }, 'google_translate_element');
      }`;
      // layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
    document.body.appendChild(gTranslateScript);
    document.body.appendChild(gElementInit);
  
    return (): void => {
      gTranslateScript.remove();
      gElementInit.remove();
    };
  };
