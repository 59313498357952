import { v4 as uuidv4 } from 'uuid';
 


export class SessionStorage {

    private _sessionUUID: string;
    private _totalTokenCount: string;
    private _promptTokenCount: string;
    private _msgID: string;
    private _msgFB: string;
    
    constructor() {
       
        if(!sessionStorage.getItem('sessionUUID')) {
            this._sessionUUID = uuidv4();
            sessionStorage.setItem('sessionUUID', this._sessionUUID);
        } else {
            this._sessionUUID = sessionStorage.getItem('sessionUUID') || ''; 
        }

        if(!sessionStorage.getItem('totalTokenCount')) {
            this._totalTokenCount = "0";
            sessionStorage.setItem('totalTokenCount', this._totalTokenCount);
        } else {
            this._totalTokenCount = sessionStorage.getItem('totalTokenCount') || "0"; 
        }

        if(!sessionStorage.getItem('promptTokenCount')) {
            this._promptTokenCount = "0";
            sessionStorage.setItem('promptTokenCount', this._promptTokenCount);
        } else {
            this._promptTokenCount = sessionStorage.getItem('promptTokenCount') || "0"; 
        }

        if(!sessionStorage.getItem('msgID')) {
            this._msgID = "";
            sessionStorage.setItem('msgID', this._msgID);
        } else {
            this._msgID = sessionStorage.getItem('msgID') || ''; 
        }
        if(!sessionStorage.getItem('msgFB')) {
            this._msgFB = "neutral";
            sessionStorage.setItem('msgFB', this._msgFB);
        } else {
            this._msgFB = sessionStorage.getItem('msgFB') || ''; 
        }

    }

     

    get sessionUUID(): string {
        return this._sessionUUID;
    }

    get totalTokenCount():string  {
        return  this._totalTokenCount;
    }

    get promptTokenCount():string  {
        return  this._promptTokenCount;
    }
    get msgID(): string {
        return this._msgID;
    }
    get msgFB(): string {
        return this._msgFB;
    }
    set msgID(x: string) {
        this._msgID = x;
         
    }
    set msgFB(x: string)  {
        this._msgFB = x;
    }
   
}