import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, 
    HideCitations, FeedbackRequest, ShowMsgFb, SpeechAuth} from "./models";
 
import { v4 as uuidv4 } from 'uuid';
export async function getSpeechAuthToken(): Promise<SpeechAuth|void> {
    const response = await fetch('/speech/issueToken');
    if (!response.ok) {
        console.log("Can't retrieve access token, speech will be disabled.")
        return;
    }

    const payload = await response.json();
    return payload;
}
export async function gethideCitations(): Promise<HideCitations> {
    const response = await fetch("/showHideCitations", {
        method: "GET"
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.hideCitations){
            formattedResponse = respJson.hideCitations
        }else{
            formattedResponse = "false"
            
        }
         
        if(!res.ok){
            return {
                hideCitations: "false"
            }
        }else{
            return {
                hideCitations: formattedResponse
            }
        }
    })
    .catch((err) => {
        console.log(err)
        console.error("Hide/Show Citation issue.");
        return {
            hideCitations: "false"
        }
    })
    return response;
}

export async function gethideMsgFb(): Promise<ShowMsgFb> {
    const response = await fetch("/showHideMsgFb", {
        method: "GET"
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.showMsgFb){
            formattedResponse = respJson.showMsgFb
        }else{
            formattedResponse = "false"
            
        }
         
        if(!res.ok){
            return {
                showMsgFb: "false"
            }
        }else{
            return {
                showMsgFb: formattedResponse
            }
        }
    })
    .catch((err) => {
        console.log(err)
        console.error("Hide/Show Msg Fb issue.");
        return {
            showMsgFb: "false"
        }
    })
    return response;
}

 
export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            messages: options.messages,
            //sessionUUID: options.sessionUUID,
            chatUUID: options.chatUUID
        }),
        signal: abortSignal
    });

    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}
 
    
export const historyList = async (offset=0): Promise<Conversation[] | null> => {
    //console.log("In history LIST")
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: "POST",
        body: JSON.stringify({
            user_id: sessionStorage.sessionUUID
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
         
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            
            let convMessages: ChatMessage[] = [];
            convMessages = await historyRead(conv.id, conv.userId)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.error("error fetching messages: ", err)
                return []
            })
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                date: conv.createdAt,
                messages: convMessages
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.log(err)
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}
    
export const historyRead = async (convId: string, userId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            user_id: userId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then(async (res) => {
        if(!res){
            return []
        }
        const payload = await res.json();
        let messages: ChatMessage[] = [];
        if(payload?.messages){
            payload.messages.forEach((msg: any) => {
                const message: ChatMessage = {
                    id: msg.id,
                    role: msg.role,
                    date: msg.createdAt,
                    content: msg.content,
                    context: { followup_questions: null },
                    prompt_tokens: Number(msg.prompt_tokens) || 0, //Number(sessionStorage.getItem("promptTokenCount")),
                    completion_tokens: Number(msg.completion_tokens) || 0,
                    feedbackOnMsg: msg.feedbackOnMsg?? undefined 
                }
                messages.push(message)
            });
        }
        return messages;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return []
    })
    return response
}

export const requestTTS = async (msg: string, langs: string | undefined): Promise<string | null> => {
    
    const language = document.documentElement.lang || "en-US";
    //console.log('requesting ' + language)

    return await fetch("/requestTTS", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ message: msg, language: language, langs: langs})
    }).then((res) => {
        if (res.status == 200) {
            return res.blob();
        } else if (res.status == 400) {
            console.log("Speech synthesis is not enabled.");
            return null;
        } else {
            console.error("Unable to get speech synthesis.");
            return null;
        }
    })
        .then(blob => (blob ? URL.createObjectURL(blob) : null));
    //     .catch((err) => {
    //         console.error("There was an issue in TTS.");
    //         let errorChatMsg: ChatMessage = {
    //             id: uuidv4(),
    //             role: "error",
    //             content: err,
    //             date: new Date().toISOString(),
    //             prompt_tokens: 0,
    //             completion_tokens: 0
    //         };
    //         historyLogError(errorChatMsg, cId, chatUUID,pgm);
    //         return new Response;
    //     })
    // return response
}
export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, chatUUID: string, language: string, 
    isFaq: string, pgm: string,  authType: string, authFieldsVal:string, convId?: string): Promise<Response> => {
    let body;
    //console.log(" in history generate " + authType + " " + authFieldsVal)
    let cId =''
    let iFrameEnv = window.sessionStorage.getItem("iframe_env");
    let mainEnv = window.sessionStorage.getItem("main_env");
    let isInIframe = window.sessionStorage.getItem("isInIframe");
    // console.log(`in historyGenerate: pgm: ${pgm}, isInIframe: ${isInIframe}, iFrameEnv: ${iFrameEnv}, mainEnv: ${mainEnv}`);
    if(convId){
        cId = convId
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages,
            user_id: chatUUID,
            language: language,
            isFaq: isFaq,
            pgm: pgm,
            env: {
                iframe_env: iFrameEnv,
                main_env: mainEnv
            },
            authType: authType,
            authFieldsVal: authFieldsVal,
            isInIframe: isInIframe
        })
    }else{
        body = JSON.stringify({
            messages: options.messages,
            user_id: chatUUID,
            language: language,
            isFaq: isFaq,
            pgm: pgm,
            env: {
                iframe_env: iFrameEnv,
                main_env: mainEnv
            },
            authType: authType,
            authFieldsVal: authFieldsVal,
            isInIframe: isInIframe
        })
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res 
    })
    .catch((err) => {
        console.error("There was an issue creating history.");
        let errorChatMsg: ChatMessage = {
            id: uuidv4(),
            role: "error",
            content: err,
            context: { followup_questions: null },
            date: new Date().toISOString(),
            prompt_tokens: 0,
            completion_tokens: 0 
        };
        historyLogError(errorChatMsg, cId, chatUUID,pgm);
        return new Response;
    })
    return response
}


export const historyUpdate = async (messages: ChatMessage[], convId: string, chatUUID: string, pgm: string): Promise<Response> => {
    //console.log("In History update: this is my chat UUID: " +  chatUUID)
    let iFrameEnv = window.sessionStorage.getItem("iframe_env");
    let mainEnv = window.sessionStorage.getItem("main_env");
    let isInIframe = window.sessionStorage.getItem("isInIframe");

    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages,
            user_id: chatUUID,
            pgm: pgm,
            env: {
                iframe_env: iFrameEnv,
                main_env: mainEnv
            },
            isInIframe: isInIframe
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue updating history.");
        let errorChatMsg: ChatMessage = {
            id: uuidv4(),
            role: "error",
            content: err,
            context: { followup_questions: null },
            date: new Date().toISOString(),
            prompt_tokens: 0,
            completion_tokens: 0 
        };
        historyLogError(errorChatMsg, convId, chatUUID, pgm);
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}

export const historyMessageFeedback = async (messageId: string, feedback: string, convId: string, chatUUID: string, pgm: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback,
            conversation_id: convId,
            user_id: chatUUID,
            pgm:pgm

        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue logging feedback.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}
 
export const historyLogError = async (message: ChatMessage, convId: string, chatUUID: string, pgm: string): Promise<Response> => {
    //console.log("In History Log Error : this is my chat UUID: " +  chatUUID)
    let iFrameEnv = window.sessionStorage.getItem("iframe_env");
    let mainEnv = window.sessionStorage.getItem("main_env");
    let isInIframe = window.sessionStorage.getItem("isInIframe");

    const response = await fetch("/history/logerror", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            message: message,
            user_id: chatUUID,
            pgm:pgm,
            env: {
                iframe_env: iFrameEnv,
                main_env: mainEnv
            },
            isInIframe: isInIframe
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue logging the error.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}
    
export const historyDelete = async (convId: string, chatUUID: string,pgm:string) : Promise<Response> => {
    //console.log("&&&& history delete &&&&")
    //console.log(convId)
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
            user_id: chatUUID
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errorChatMsg: ChatMessage = {
            id: uuidv4(),
            role: "error",
            content: err,
            context: { followup_questions: null },
            date: new Date().toISOString(),
            prompt_tokens: 0,
            completion_tokens: 0 
        };
        historyLogError(errorChatMsg,convId, chatUUID,pgm);
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}
    
export const historyDeleteAll = async (chatUUID: string) : Promise<Response> => {
    //console.log("&&&& history delete all &&&&")
   
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        //body: JSON.stringify({}),
        body: JSON.stringify({
            user_id: chatUUID
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}
    
export const historyClear = async (convId: string, chatUUID: string, pgm: string) : Promise<Response> => {
    let iFrameEnv = window.sessionStorage.getItem("iframe_env");
    let mainEnv = window.sessionStorage.getItem("main_env");
    let isInIframe = window.sessionStorage.getItem("isInIframe");
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            user_id: chatUUID,
            env:{
                iframe_env: iFrameEnv,
                main_env: mainEnv
            },
            isInIframe: isInIframe
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errorChatMsg: ChatMessage = {
            id: uuidv4(),
            role: "error",
            content: err,
            context: { followup_questions: null },
            date: new Date().toISOString(),
            prompt_tokens: 0,
            completion_tokens: 0 
        };
        historyLogError(errorChatMsg,convId, chatUUID,pgm);
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}
    
export const historyRename = async (convId: string, title: string, chatUUID: string,pgm: string) : Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title,
            user_id: chatUUID,
            pgm:pgm
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}
    
export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    const response = await fetch("/history/ensure", {
        method: "GET",
    })
    .then(async res => {
        let respJson = await res.json();
        let formattedResponse;
        if(respJson.message){
            formattedResponse = CosmosDBStatus.Working
        }else{
            if(res.status === 500){
                formattedResponse = CosmosDBStatus.NotWorking
            }else{
                formattedResponse = CosmosDBStatus.NotConfigured
            }
        }
        if(!res.ok){
            return {
                cosmosDB: false,
                status: formattedResponse,
                allowMessageDelete: "false"
            }
        }else{
            return {
                cosmosDB: true,
                status: formattedResponse,
                allowMessageDelete: respJson.allowMessageDelete
            }
        }
    })
    .catch((err) => {
        console.log(err)
        console.error("There was an issue fetching your data.");
        return {
            cosmosDB: false,
            status: err,
            allowMessageDelete: "false"
        }
    })
    return response;
}
export const load = async (): Promise<Response | null> => {

    const response = await fetch("/load", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}
export const feedbackGenerate = async (options: FeedbackRequest, abortSignal: AbortSignal, chatUUID: string, convId: string,pgm:string): Promise<Response> => {
    let body;
    body = JSON.stringify({
            conversation_id: convId,
            message: options.message,
            user_id: chatUUID,
            pgm:pgm
    })
    
    const response = await fetch("/feedback/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res 
    })
    .catch((err) => {
        console.error("There was an issue saving feedback.");
        let errorChatMsg: ChatMessage = {
            id: uuidv4(),
            role: "error",
            content: err,
            context: { followup_questions: null },
            date: new Date().toISOString(),
            prompt_tokens: 0,
            completion_tokens: 0 
        };
        historyLogError(errorChatMsg, convId, chatUUID,pgm);
        return new Response;
    })
    return response
}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}
export const refreshfrontendSettings = async (pgm: string, language: string): Promise<Response | null> => { 
    //console.log("IN REFRESH FRONTEND SETTINGS: this is pgm: " + pgm)   
    let isInIframe = window.sessionStorage.getItem("isInIframe") || ''; 
    if (isInIframe === 'true') {
        window.sessionStorage.setItem("iframe_env", pgm);
    } else {
        window.sessionStorage.setItem("main_env", pgm);
    }
    // console.log(`pgm in refreshfrontendSettings: ${pgm}, isInIframe: ${isInIframe}`);

    const response = await fetch("/refresh_frontend_settings/" + pgm, {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}
export const refreshEnv = async (pgm: string): Promise<Response> => {
    //console.log("In refresh env: this is pgm: " + pgm)
    const response = await fetch("/env/" + pgm, {
        method: "GET",
    }).then(async (res) => {
        return res
    })
        .catch((err) => {
            console.error("There was an issue refreshing env. " + err);

            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response
}

export const pii_redaction = async (q: string, language: string): Promise<Response> => {
    
    let body;
    body = JSON.stringify({
        question: q,
        language: language
    })

    const response = await fetch("/pii_recognition", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
    }).then(async (res) => {
        return res
    })
        .catch((err) => {
            console.error("There was an issue in pii redaction. " + err);

            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response
}

