import { useState, useContext, useCallback, useEffect } from "react";
import { CommandBarButton, Stack, TextField, ThemeSettingName, fontFace } from "@fluentui/react";
import { AppStateContext } from "../../state/AppProvider";
import MediaQuery from "react-responsive";
import { Rating } from 'react-simple-star-rating'
/*import { ReCAPTCHA } from 'react-google-recaptcha';*/
import styles from "./Feedback.module.css";
import TextareaAutosize from "react-textarea-autosize";

interface Props {
    onSend: (rating: number, feedback: string, id?: string, messageid?:string, feedbackmsg?: string) => void;
    disabled: boolean;
    darkmode: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
    thankYouMessagePayload: string | null; 
    showThankYouMessage: boolean;
    setShowThankYouMessage: (value: boolean) => void;
    setPopUpBlocker: (popUpBlocker: boolean) => void;
}

export const Feedback = ({ 
                        onSend, disabled, darkmode, placeholder, clearOnSend, conversationId, thankYouMessagePayload,
                        showThankYouMessage, setShowThankYouMessage, setPopUpBlocker
                        }: Props) => {
    const [feedback, setFeedback] = useState<string>("");
    const [textAreaCount, setTextAreaCount] = useState(0);
    const [rating, setRating] = useState(0);
    const appStateContext = useContext(AppStateContext);
    const handleRating = (rate: number) => {
        //console.log("rate:" + rate);
        setRating(rate)
    
        // other logic
    }
   
    // Optional callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => {
        console.log(value, index)
         
    };

    const closeFeedback   = () => {
        setPopUpBlocker(false);
        setFeedback("");
        setRating(0);
        setTextAreaCount(0);
        sessionStorage.msgID = "";
        sessionStorage.msgFB = "neutral"
        appStateContext?.dispatch({ type: 'MSGFB', payload: 'N' })
        appStateContext?.dispatch({ type: 'CLOSE_FEEDBACK_PANEL' })
        setShowThankYouMessage(false);
    };  
    
    const sendFeedback = () => {
    
        if (disabled || (!feedback.trim() && rating  == 0)) {
            return;
        }

        if(conversationId){
            let messageId = sessionStorage.msgID;
            let messageFb = sessionStorage.msgFB;
            //console.log("FB state" + appStateContext?.state.isMsgFB);
            if (appStateContext?.state.isMsgFB === "N"){
                messageId="";
                messageFb="neutral";
            }
            //console.log("FB msgID" + messageId);
            onSend(rating  , feedback, conversationId, messageId, messageFb);
            
            setFeedback("");
            setRating(0);
            setTextAreaCount(0);
            sessionStorage.msgID = "";
            sessionStorage.msgFB = "neutral"
            appStateContext?.dispatch({ type: 'MSGFB', payload:'N' })
            
        }else{
           return;
        }
        
        if (clearOnSend) {
            setFeedback("");
            setRating(0);
            setTextAreaCount(0);
        }
        
        //console.log('thankYouMessagePayload:', thankYouMessagePayload);
        //console.log('rating:', rating);
        setShowThankYouMessage(true);
        const timer = setTimeout(() => {
            closeFeedback();
            setPopUpBlocker(false);
        }, 3000);
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendFeedback();
        }
    };

    // const onFeedbackChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    //     var txt = ""
    //     if (newValue) {
    //         txt = newValue;
    //     }
    //     //console.log("rate:" +  rating);
    //     setTextAreaCount(txt.length);
    //     setFeedback(newValue || "");
    // };
    const onFeedbackChange = useCallback((_ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        var txt = ""
        if (_ev.target.value ?? "") {
            txt = _ev.target.value ?? ""
        }
        setTextAreaCount(txt.length);
        setFeedback(txt);
       
        },
        [setFeedback, setTextAreaCount],
    );

    const sendFeedbackDisabled = disabled || (!feedback.trim() && rating  == 0);

    let titleSeperatorValue = "out of";
    const [titleSeparator, setTitleSeparator] = useState<string>(titleSeperatorValue);   
   
    const updateTitleSeparator = (element: HTMLElement | null) => {
        if (element) {
            setTitleSeparator(element.innerText);
        }
    };
    
    useEffect(() => {
        const hiddenElement = document.getElementById("hidden_title_separator");        
   
        updateTitleSeparator(hiddenElement); 
    
        if (hiddenElement) {
            const observer = new MutationObserver(() => {
                updateTitleSeparator(hiddenElement);
            });
    
            observer.observe(hiddenElement, {
                childList: true, 
                subtree: true,
                characterData: true 
            });    
     
            return () => {
                observer.disconnect();
            };
        }
    }, []);


    return (
        <Stack horizontal horizontalAlign="center">
            <div>
                <div id="hidden_title_separator" style={{ display: 'none' }}>{titleSeperatorValue}</div>
                <div className={`${styles.feedbackGeneralTextWithTopMargin} ${showThankYouMessage ? styles.visible : styles.hidden}`}>
                    {(thankYouMessagePayload === 'positive' && rating > 3) ? (
                        <>
                            Thank you for sharing your positive feedback! Your input is valuable and will assist us in enhancing our service for you and others in the future.
                        </>
                    ) : (
                        <>
                            Thank you for sharing your feedback! Your input is valuable and will assist us in enhancing our service for you and others in the future.
                        </>
                    )}
                </div>
                <Stack className={`${styles.feedbackContainer} ${showThankYouMessage ? styles.hidden : styles.visible}`}>
                    <span className={styles.feedbackGeneralText}>
                        Your feedback is important to us! Let us know how Priya is doing by filling out the form
                        below. Please do not include any confidential information, such as Social Security
                        Numbers, within this form.
                        <br />
                        <div className={styles.starText}>
                            How would you rate your overall experience?
                            <Stack horizontal className={styles.feedback5starContainer}>
                                <Rating
                                    onClick={handleRating}
                                    fillColor={"#287165"}
                                    SVGstrokeColor={"#287165"}
                                    SVGstorkeWidth={"2"}
                                    emptyColor={darkmode ? "#1C1B2C" : "#FFFFFF"}
                                    iconsCount={5}
                                    allowFraction
                                    initialValue={rating}
                                    titleSeparator={titleSeparator}
                                />
                            </Stack>
                        </div>
                        <div className={styles.expText}>
                            Please share any details about your experience.<br />
                            <Stack horizontal horizontalAlign="center" className={styles.feedbackInputContainer}>
                                <TextareaAutosize
                                    className={styles.feedbackInputTextArea}
                                    placeholder={placeholder}
                                    value={feedback}
                                    maxRows={5}
                                    name="txtQuestionInputText"
                                    aria-label="Question input"
                                    onChange={onFeedbackChange}
                                    onKeyDown={onEnterPress}
                                    maxLength={300}
                                />
                            </Stack>
                            <div className={styles.feedbackCharCount}>{`${textAreaCount}/300`}</div>
                        </div>
                    </span>
                </Stack>
                {!showThankYouMessage && (
                    <Stack horizontal horizontalAlign="center" className={styles.submitButtonContainer}>
                        <CommandBarButton
                            role="button"
                            className={styles.closeButton}
                            onClick={closeFeedback}
                            text="CANCEL"
                            aria-label="CANCEL"
                            title="CANCEL"
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? closeFeedback() : null}
                        />
                        <CommandBarButton
                            role="button"
                            className={sendFeedbackDisabled ? styles.submitButtonDisabled : styles.submitButton}
                            onClick={sendFeedback}
                            text="SUBMIT"
                            aria-label="Submit Feedback"
                            title="SUBMIT"
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? sendFeedback() : null}
                        />
                    </Stack>
                )}
            </div>
        </Stack>
    );
};
