import { useState, useCallback, useContext, useRef, useEffect } from "react";
import { Stack } from "@fluentui/react";
import TextareaAutosize from "react-textarea-autosize";
import Send from "../../assets/send.png";
import SendDisabled from "../../assets/DisabledSendReg.svg";
// import Microphone from "../../assets/greenMic.svg";
import styles from "./QuestionInput.module.css";
// import ReactMarkdown from "react-markdown";
import { AppStateContext } from "../../state/AppProvider";
// import { MicRegular, MicFilled} from "@fluentui/react-icons";
import { SpeechInput } from "./SpeechInput";
import { set } from "lodash";
interface Props {
    onSend: (question: string, id?: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
    speechEnabled?: string;
    stopMsgPlaying: boolean;
    setStopMsgPlaying: (stopMsgPlaying: boolean) => void;
    popUpBlocker: boolean;
    setPopUpBlocker: (popUpBlocker: boolean) => void;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, conversationId, speechEnabled, stopMsgPlaying, setStopMsgPlaying, popUpBlocker, setPopUpBlocker }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const appStateContext = useContext(AppStateContext)
    const branding = appStateContext?.state.frontendSettings?.branding;
    const [microphoneActive, setmicrophoneActive] = useState<boolean>(false);
    const speechWaitingMsg = branding?.speech_waiting_msg ? branding?.speech_waiting_msg : "I'm listening...";
    const speechErrorMsg = branding?.speech_error_msg ? branding?.speech_error_msg : "I'm sorry I am having difficulties understanding. Please ensure your microphone is working properly or select a language above.";
    const inputRef = useRef<HTMLTextAreaElement>(null); 

    const sendQuestion = () => {
        if (disabled || !question.trim() || question.trim() === speechErrorMsg || question.trim() === speechWaitingMsg) {
            return;
        }
        if(conversationId){
            onSend(question, conversationId);
        }else{
            onSend(question);
        }
        if (clearOnSend) {
            setQuestion("");
        }
    };
    
    
   
    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey  && !(ev.nativeEvent?.isComposing === true)) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = useCallback(
        (_ev: React.ChangeEvent<HTMLTextAreaElement>) => {
          setQuestion(_ev.target.value ?? "");
        },
        [setQuestion],
      );

    const sendQuestionDisabled = disabled || !question.trim() || microphoneActive || question.trim() === speechErrorMsg;
    useEffect(() => {

        //console.log("**Q stopMsgPlaying " + microphoneActive);
        setStopMsgPlaying(microphoneActive);
       

    }, [microphoneActive]);
    
    useEffect(() => {
        if (window.self === window.top) {
            // Set focus on main page when loaded
            inputRef.current?.focus();
        }
    }, []);

    return (
        <Stack horizontal className={microphoneActive? styles.questionInputContainerFocus : styles.questionInputContainer}>
            <div className={styles.questionInputText}> 
         
             <TextareaAutosize 
                ref={inputRef}       
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                maxRows={5} 
                name="txtQuestionInputText"
                aria-label="Question input"
            />
            </div>
            <div className={styles.questionInputSendButtonContainer} 
                role="button" 
                tabIndex={0}
                title="Ask question"
                aria-label="Ask question button"
                onClick={() => !popUpBlocker && sendQuestion()}
                onKeyDown={e => !popUpBlocker && (e.key === "Enter" || e.key === " ") ? sendQuestion() : null}
                style={{ cursor: popUpBlocker ? 'not-allowed' : 'pointer' }}
            >
                { sendQuestionDisabled ? 
               
                    <img src={SendDisabled} className={styles.questionInputSendButtonDisabled} alt="Send Question"/>
                :   
                    <img src={Send} className={styles.questionInputSendButton} alt="Send Question"/>
                
                }
            </div>
            {speechEnabled  === 'Y' &&
          
                <SpeechInput updateQuestion={setQuestion} microphoneActive={microphoneActive} setmicrophoneActive={setmicrophoneActive} question={question.trim()} popUpBlocker={popUpBlocker} setPopUpBlocker={setPopUpBlocker}/>
            
                   }
           
            {/* <div className={styles.questionInputBottomBorder} /> */}
        </Stack>
       
    );
};
