import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import { useContext, useEffect } from "react";
import { AppStateContext } from "../../state/AppProvider";
 
 

const Layout = () => {

    const appStateContext = useContext(AppStateContext)

    useEffect(() => {}, [appStateContext?.state.isCosmosDBAvailable.status]);

    return ( 
        <div className={styles.layout}>
            
            <Outlet />
            
        </div>
    );
};

export default Layout;